import { KeycloakApi } from "../../api/KeycloakApi";
import { LoginDto, TokenDto } from "../models/LoginDto";
import { BooleanThunk } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";
import { getTenant } from "../../api/ApiUtils";

export const LOGIN_STATE = "LOGIN_STATE";

export const thunkLogin =
  (loginDto: LoginDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const token = await KeycloakApi.login(loginDto);
      setToken(token);
      dispatch({
        type: LOGIN_STATE,
        payload: true,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Fehler beim Login", e));
    }
    return false;
  };

export async function ensureToken(useSelfToken: boolean = true): Promise<TokenDto | undefined> {
  const token = getValidToken(useSelfToken);
  if (!token) {
    if (useSelfToken) {
      logout();
      return;
    }
    let tenant = getTenant().toUpperCase();
    const tenantCredential = {
      username: window.env[`REACT_APP_${tenant}_PUBLIC_USER`] || process.env[`REACT_APP_${tenant}_PUBLIC_USER`],
      password: window.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`] || process.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`],
    } as LoginDto;

    if (!tenantCredential.username || !tenantCredential.password) {
      console.log("Missing tenant credentials");
      return;
    }
    const publicToken = await KeycloakApi.login(tenantCredential);
    setToken(publicToken, false);
    return getValidToken(useSelfToken);
  }
  return token;
}

function setToken(token: string, useSelfToken: boolean = true) {
  if (useSelfToken) {
    localStorage.setItem("token", token);
    localStorage.setItem("token_timestamp", String(Date.now()));
  } else {
    localStorage.setItem("public_token", token);
    localStorage.setItem("public_token_timestamp", String(Date.now()));
  }
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("public_token");
  localStorage.removeItem("token_timestamp");
  localStorage.removeItem("public_token_timestamp");
}

export function isLoggedIn(): boolean {
  const token = getValidToken();
  return token !== undefined;
}

function getValidToken(useSelfToken: boolean = true): TokenDto | undefined {
  const rawToken = useSelfToken ? localStorage.getItem("token") : localStorage.getItem("public_token");
  if (!rawToken) return; // no token found in local storage
  try {
    const token = JSON.parse(rawToken) as KeycloakToken;
    if (!token) return; // token from local storage could not be parsed
    if (!token.access_token) return; // token from local storage doesn't contain an access_token
    const jwt = parseJwt(token.access_token);
    if (!jwt) return; // access_token could not be parsed as an JWT
    const tokenAge = getTokenAge(token, useSelfToken);
    if (tokenAge === undefined) return; // token age can be 0
    useSelfToken ? registerRefreshToken(token, tokenAge) : registerRefreshPublicToken(token, tokenAge);
    return {
      access_token: token.access_token,
      customer_number: jwt.customer_number,
    };
  } catch {
    // no token is returned
  }
}

let isRefreshTokenRegistered = false;

function registerRefreshToken(token: KeycloakToken, tokenAge: number) {
  if (isRefreshTokenRegistered) return;
  const refreshTokenFunc = async () => {
    const rawRefreshToken = await KeycloakApi.refresh(token.refresh_token);
    setToken(rawRefreshToken);
    // retrigger token refresh
    isRefreshTokenRegistered = false;
    getValidToken();
  };

  const timeRemaining = token.expires_in - tokenAge;
  setTimeout(refreshTokenFunc, timeRemaining * 1000);
  isRefreshTokenRegistered = true;
}

let isRefreshPublicTokenRegistered = false;

function registerRefreshPublicToken(token: KeycloakToken, tokenAge: number) {
  if (isRefreshPublicTokenRegistered) return;
  const refreshPublicTokenFunc = async () => {
    const rawRefreshToken = await KeycloakApi.refresh(token.refresh_token);
    setToken(rawRefreshToken, false);
    // retrigger public token refresh
    isRefreshPublicTokenRegistered = false;
    getValidToken(false);
  };

  const timeRemaining = token.expires_in - tokenAge;
  setTimeout(refreshPublicTokenFunc, timeRemaining * 1000);
  isRefreshPublicTokenRegistered = true;
}

function parseJwt(accessToken: string): LoyaltyToken {
  return JSON.parse(atob(accessToken.split(".")[1]));
}

interface KeycloakToken {
  access_token: string;
  expires_in: number;
  "not-before-policy": number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

interface LoyaltyToken {
  acr: string;
  "allowed-origins": string[];
  azp: string;
  customer_number: string;
  email: string;
  exp: number;
  groups: string[];
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sub: string;
  typ: string;
  upn: string;
}

export async function tokenRequestOptions(method: string, useSelfToken: boolean = true) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await ensureToken(useSelfToken);
  if (!token) return;
  requestOptions.headers = {
    ...requestOptions.headers,
    Authorization: "Bearer " + token.access_token,
  };

  return requestOptions;
}

export async function parseResponse(response: Response) {
  if (response.status === 401) {
    logout();
    return;
  }
  const text = await response.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch {
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) throw response.statusText;
  }
  if (data && data.error) {
    throw data.error;
  }
  return data;
}

function getTokenAge(token: KeycloakToken, useSelfToken: boolean = true): number | undefined {
  const tokenTimestamp = useSelfToken
    ? localStorage.getItem("token_timestamp")
    : localStorage.getItem("public_token_timestamp");
  if (!tokenTimestamp) return; // missing timestamp for token
  const tokenAge = Math.trunc((Date.now() - Number(tokenTimestamp)) / 1000);
  if (tokenAge > token.refresh_expires_in) return; // token has already expired and can't be refreshed
  return tokenAge;
}
